@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html,
  body {
    height: 100%;
    min-width: 320px;
  }

  body {
    @apply bg-slate-800 text-gray-100 leading-normal;
    font-family: 'Raleway', sans-serif;
    line-height: 1.5;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-font-smoothing: antialiased; /* Chrome, Safari */
    -moz-osx-font-smoothing: grayscale; /* Firefox */
  }

  h1,
  .h1 {
    @apply text-xl leading-none font-semibold lg:text-2xl;
  }

  h2 {
    @apply leading-none font-semibold lg:text-lg;
  }

  p:not(.h1) + p,
  p:not(.h1) + ul {
    @apply mt-4;
  }

  strong {
    @apply font-semibold;
  }

  a {
    @apply underline underline-offset-2 decoration-1 transition-colors;
  }

  a:focus,
  button:focus {
    @apply outline-1 outline-dotted outline-offset-2 outline-orange-400;
  }
}

@layer components {
  .header {
    @apply relative z-10 md:flex md:self-start md:flex-col md:items-center md:w-1/4 md:max-h-full md:min-h-screen md:py-8 md:px-8 md:sticky md:top-0 md:bg-slate-700 lg:py-12;
  }

  .nav-wrapper {
    @apply fixed inset-0 overflow-y-auto transition-transform -translate-x-full bg-slate-800/80 backdrop-blur-sm py-8 md:static md:overflow-auto md:transition-none md:translate-x-0 md:bg-transparent md:backdrop-blur-none md:py-0;
  }

  .mobile-nav-open .nav-wrapper {
    @apply translate-x-0;
  }

  .nav-toggle {
    @apply flex items-center justify-center w-10 h-10 p-2 rounded-full bg-orange-400/80 md:hidden;
  }

  .nav-toggle__bars {
    @apply bg-gray-100 relative block w-6 h-[2px] transition-transform;
  }

  .nav-toggle__bars::before,
  .nav-toggle__bars::after {
    @apply bg-gray-100 absolute block w-6 h-[2px] transition-transform;
    content: '';
  }

  .nav-toggle__bars::before {
    top: -7px;
  }

  .nav-toggle__bars::after {
    bottom: -7px;
  }

  .mobile-nav-open .nav-toggle__bars {
    transform: rotate(45deg);
  }

  .mobile-nav-open .nav-toggle__bars::before {
    transform: rotate(-90deg) translateX(-7px);
  }

  .mobile-nav-open .nav-toggle__bars::after {
    opacity: 0;
  }

  .panel strong {
    @apply text-orange-400 font-normal;
  }
}
